import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/PlotMarker.jpg';

const DCE9 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/8" next="/portfolio">
        <p>
          <span>Plot Marker</span>
          <br />
          These are designed to give the users of the system their own personal place within the gardens and incentify
          them through a system of badges. These would be riveted on to the signs which themselves are bolted into the
          garden plot. With a set number of items donated to the system they recieve a new badge giving them a sense of
          pride spurring competition and productivity.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Denver Crop Exchange" className={rightStyle} />
    </Aux>
  );
};

export default DCE9;
