import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG1 from "../../../assets/images/thesis/catalog_toc.jpg";

const Exhib3 = () => {
  const rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn
        title="Existing in Blackness"
        prev="/portfolio/exhib/2"
        next="/portfolio/exhib/4"
      >
        <p>
          <span>Communication</span>
          <br />
          Each member was assigned a committee and job. I was responsible for
          the design of the cover for this years catalog and post card as well
          as consolidating the deliverables for both groups into a coherent
          whole.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Existing in Blackness" />
      </div>
    </Aux>
  );
};

export default Exhib3;
