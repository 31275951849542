import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG from "../../../assets/images/twr/FreeStanding.jpg";

import pstyles from "../PortfolioStyles.module.css";

const TWR5 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/4" next="/portfolio/twr/6">
        <p>
          <span>System Inventory</span>
          <br />
          <br />
          My hypothetical wayfinding solution would find realization in a whole system of vectorized items. These
          elements would be placed along routes in and around the site working like a bread crumb trail. This will
          enhance the experience in a space that can become quite hectic during peak hours. This is the monolithic
          freestanding pylon which primarily signals to drivers entering the neighboring parking lots.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR5;
