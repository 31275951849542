import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

// Images
import IMG from "../../../assets/images/twr/Hero.png";

const TWR1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Tivoli Wayfinding Redesign" prev="/portfolio/" next="/portfolio/twr/2">
        <p>Environmental Graphic Design</p>
        <p>Branding</p>
        <p>Wayfinding</p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR1;
