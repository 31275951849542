import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/cc/Vector1.png';
import IMG2 from '../../../assets/images/cc/Vector2.png';

const CC4 = () => {
  let rightStyle = pstyles.TwoByOne + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/3" next="/portfolio/cc/5">
        <p>
          <span>Brandmark Vectors</span>
          <br />
          From here, the mark was refined as a vector and color systems were designed. The use of the Pantone Matching
          System aided in the selection. Ultimately, a combination of concepts was chosen as the final brand identity.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Castlewood Catastrophe" />
        <img src={IMG2} alt="Castlewood Catastrophe" />
      </div>
    </Aux>
  );
};
export default CC4;
