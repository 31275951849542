import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/thesis/gloom_early.jpg';
import IMG2 from '../../../assets/images/thesis/complicity_early.jpg';
import IMG3 from '../../../assets/images/thesis/vision_early.jpg';
import IMG4 from '../../../assets/images/thesis/gloom_mid.jpg';
import IMG5 from '../../../assets/images/thesis/complicity_mid.jpg';
import IMG6 from '../../../assets/images/thesis/vision_mid.jpg';

const Thesis4 = () => {
  const rightStyle = pstyles.ThreeByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Existing in Blackness" prev="/portfolio/thesis/3" next="/portfolio/thesis/5">
        <p>
          <span>Mask Sketches</span>
          <br />I began to look at the barriers indexically instead looking at the relation people have to the forces
          that be. These became the masks which allow some degree of navigation within this system. This concept would
          be refined as more research was applied.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Existing in Blackness" />
        <img src={IMG2} alt="Existing in Blackness" />
        <img src={IMG3} alt="Existing in Blackness" />
        <img src={IMG4} alt="Existing in Blackness" />
        <img src={IMG5} alt="Existing in Blackness" />
        <img src={IMG6} alt="Existing in Blackness" />
      </div>
    </Aux>
  );
};

export default Thesis4;
