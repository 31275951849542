import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';
import styles from './DCE.module.css';

//Images
import IMG1 from '../../../assets/images/dce/BrandMark1.png';
import IMG2 from '../../../assets/images/dce/BrandMark2.png';

const DCE3 = () => {
  let rightStyle = pstyles.TwoRows + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/2" next="/portfolio/dce/4">
        <p>
          <span>Brand Mark</span>
          <br />
          A cohesive system starts with the identity. Figuring out this important elelment during the early stages
          of a project helps to guide the rest. A number of concepts and thumbnails were produced after gathering
          research about the site and its history.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Denver Crop Exchange" className={styles.DCE3_1} />
        <img src={IMG2} alt="Denver Crop Exchange" className={styles.DCE3_2} />
      </div>
    </Aux>
  );
};

export default DCE3;
