import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/thesis/complicity_closeup.jpg';
import IMG2 from '../../../assets/images/thesis/paint_booth.jpg';

const Thesis2 = () => {
  const rightStyle = pstyles.TwoCols + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Existing in Blackness" prev="/portfolio/thesis" next="/portfolio/thesis/3">
        <p>
          <span>Art Direction</span>
          <br />
          This project was not only an exploration of the way in which black mixed members of society might navigate
          their world but an excercise in art direction. I developed the concept over the period of 3 months
          synthesizing research, materials, and production methods into a coherent whole. <br />
          <br />
          To produce the mask forms, I worked with CNC Denver. This local shop was helpful in working through the
          technical hurdles of fabrication. The use of this contemporary technique worked with my concept of bridging
          the old with new. <br />
          <br />
          Painting the elements was done at my family’s auto body shop leveraging 3 generations of visual problem
          solvers. The use of high volume low pressure painting techniques generally reserved for vehicles expidited the
          process and created a prestine finish on the product. <br />
          <br />I also included a light and color element which required sourcing and a number of tests to find the best
          option for the problem. This light was a pivitol part of the piece. Giving it the spiritual element that
          activates not only the installation space but the concept.
        </p>
      </LeftColumn>

      <div className={rightStyle}>
        <img src={IMG1} alt="Complicity" />
        <img src={IMG2} alt="Paint Booth" />
      </div>
    </Aux>
  );
};

export default Thesis2;
