import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/bawm/3_1.jpg';
import IMG2 from '../../../assets/images/bawm/3_2.jpg';
import IMG3 from '../../../assets/images/bawm/3_3.jpg';
import IMG4 from '../../../assets/images/bawm/3_4.jpg';

const BAWM3 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Black American West Museum" prev="/portfolio/bawm/2" next="/portfolio/bawm/4">
        <p>
          <span>Problem</span>
          <br />
          My proposed solution would reveal the inner character of Justina Ford’s home practice turned museum by
          literally putting it on the outside. This would mix a number of styles and ideas such as Emory Douglas, Paula
          Scher, old often racist iconograpy/ advertising into a refreshed placemaking vision.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Black American West Museum" />
        <img src={IMG2} alt="Black American West Museum" />
        <img src={IMG3} alt="Black American West Museum" />
        <img src={IMG4} alt="Black American West Museum" />
      </div>
    </Aux>
  );
};

export default BAWM3;
