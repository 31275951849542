import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/bawm/Cover1.5x.jpg';

const BAWM1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn alt={true} title="Black American West Museum" prev="/portfolio/" next="/portfolio/bawm/2">
        <p>Environmental Graphic Design</p>
        <p>Branding</p>
        <p>Narrative Design</p>
      </LeftColumn>
      <img src={IMG} alt="Black American West Museum" className={rightStyle} />
    </Aux>
  );
};

export default BAWM1;
