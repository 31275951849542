import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/Cover.jpg';

const CC7 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/6" next="/portfolio/cc/8">
        <div>
          <p>
            <span>Environmental Graphic Design</span>
            <br />
            The design of the narrative wayfinding system references the large resevoir that one stood at the location
            in blue. The grey forms evoke the destroyed dam. Throughout the system there are archival photography
            relating to the site and the heroes that worked together to save the lives of those downstream during the
            dam’s fateful collapes.
          </p>
        </div>
        <div>
          <p>
            <span>Monolith Signage</span>
            <br />
            Functions as the start or end point within the narrative. The largest size item in the inventroy it can
            serve as a beacon drawing people to it, sparking intrigue.
          </p>
        </div>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC7;
