import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/hb/Signature.png';

const HB3 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Happy Brandz Identity Design" prev="/portfolio/hb/2" next="/portfolio/hb/4">
        <p>
          <span>Brand Mark</span>
          <br />
          Before we could present a new deck to stake holders, I was tasked with redesigning the identity of the company
          that governs the Happy line of cannabis edibles. To present the idea that at this level we are not cannabis
          handleing, I chose a corporate lettermark that does not rely on marijuana imagery. Instead I leveraged the
          life style we champion. That of happy feelings through consumption or the warmth of respectful relationships
          between producers, venders, and consumers. <br />
          <br />
          People are at the core of what we do and who we are. Be happy.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Happy Brandz" className={rightStyle} />
    </Aux>
  );
};

export default HB3;
