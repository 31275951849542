import React from "react";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import styles from "./PortfolioHome.module.css";

//Images
import BAWMIMG from "../../../assets/images/bawm/Cover.jpg";
import CCIMG from "../../../assets/images/cc/cc_elev.jpg";
import DCEIMG from "../../../assets/images/dce/Cover.jpg";
import EXIMG from "../../../assets/images/exhib/Cover.jpg";
import HBIMG from "../../../assets/images/hb/Cover.png";
import TIMG from "../../../assets/images/thesis/Cover.jpg";
import TWRIMG from "../../../assets/images/twr/Hero.png";
import WDPIMG from "../../../assets/images/wdp/Cover.jpg";

const PortfolioHome = () => (
  <Aux>
    <LeftColumn title="Portfolio">
      <p>
        A selection of works which highlight Cheyne's interests and strengths as
        a designer.
      </p>
    </LeftColumn>
    <div className={styles.ContentRight}>
      <div className={styles.ImagesLeft}>
        <Link to="/portfolio/dce">
          <img src={DCEIMG} alt="Denver Crop Exchange" />
        </Link>
        <Link to="/portfolio/twr">
          <img
            src={TWRIMG}
            alt="Tivoli Wayfinding Redesign"
            className={styles.Right2}
          />
        </Link>
        <Link to="/portfolio/bawm">
          <img src={BAWMIMG} alt="Black American West Museum" />
        </Link>
        <Link to="/portfolio/hb">
          <img src={HBIMG} alt="Happy Brandz" className={styles.Right3} />
        </Link>
      </div>
      <div className={styles.ImagesRight}>
        <Link to="/portfolio/wdp">
          <img src={WDPIMG} alt="WalkDenver Placemaking" />
        </Link>
        <Link to="/portfolio/cc">
          <img src={CCIMG} alt="Castlewood Catastrophe" />
        </Link>
        <Link to="/portfolio/exhib">
          <img src={EXIMG} alt="Exhibition Catalog" className={styles.Right4} />
        </Link>
        <Link to="/portfolio/thesis">
          <img src={TIMG} alt="Existing in Blackness" />
        </Link>
      </div>
    </div>
  </Aux>
);

export default PortfolioHome;
