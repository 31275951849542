import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/wdp/Map.jpg';

const WDP2 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="WalkDenver Placemaking" prev="/portfolio/wdp" next="/portfolio/wdp/3">
        <div>
          <p>
            <span>Problem</span>
            <br />
            What types of graphics and environmental graphic design concepts could be utilized to include the cultural
            identity of the neighborhood? What can be done on an experiential graphic design level to promote
            walkability? <br />
            <br />
            The blue lines on this map of Montbello denotes the “Walkable Loop” while the orange circles were locations
            for art, wayfinding, and singage opportunities.
          </p>
          <p>
            <span>Solution</span>
            <br />
            Design a system of site specific placemaking elements with interactive elements. These literally elevate the
            neighborhood and give the user a better vantage of the art being done in the neighborhood. <br />
            <br />
            My concept also gave considerations for a hashtag so that campaign could be shared with those outside the
            communitty. Fostering a positive image for us.
          </p>
        </div>
      </LeftColumn>
      <img src={IMG} alt="WalkDenver Placemaking" className={rightStyle} />
    </Aux>
  );
};

export default WDP2;
