import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG from "../../../assets/images/thesis/catalog_cover.jpg";

const Exhib1 = () => {
  const rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Exhibition Catalog" prev="/portfolio/" next="/portfolio/exhib/2">
        <p>Thesis Exhibition</p>
        <p>Cover Design</p>
        <p>Layout</p>
      </LeftColumn>
      <img src={IMG} alt="Catalog Cover" className={rightStyle} />
    </Aux>
  );
};

export default Exhib1;
