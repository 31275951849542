import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/bawm/FrontFacade.jpg';

const BAWM2 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Black American West Museum" prev="/portfolio/bawm/" next="/portfolio/bawm/3">
        <div>
          <p>
            <span>Problem</span>
            <br />
            As gentrification changes the identity of the 5-points neighborhood from , it is important that those coming
            in have an understanding of the history of the western region and the contribution made by African-American
            Pioneers.
          </p>
          <p>
            <span>Solution</span>
            <br />
            Updates to the visual identity and signage of the museum to bring greater recognition and value as an
            institution for the city of Denver and its people.
          </p>
        </div>
      </LeftColumn>
      <img src={IMG} alt="Black American West Museum" className={rightStyle} />
    </Aux>
  );
};

export default BAWM2;
