import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Arrows.module.css';

const Arrows = props => {
  let leftArrow = undefined;
  let rightArrow = undefined;
  
  if (props.hideLeft === true) {
    leftArrow = <div className={styles.hide}>&#9664;</div>;
  } else {
    leftArrow = <Link to={props.prev}>&#9664;</Link>
  }


  if (props.hideRight === true) {
    rightArrow = <div className={styles.hide}>&#9654;</div>;
  } else {
    rightArrow = <Link to={props.next}>&#9654;</Link>
  }

  return (
    <div className={styles.Arrows}>
      {leftArrow}
      {rightArrow}
    </div>
  );
};

export default Arrows;
