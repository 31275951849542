import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/wdp/3_1.jpg';
import IMG2 from '../../../assets/images/wdp/3_2.jpg';
import IMG3 from '../../../assets/images/wdp/3_3.jpg';
import IMG4 from '../../../assets/images/wdp/3_4.jpg';

const WDP3 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="WalkDenver Placemaking" prev="/portfolio/wdp/2" next="/portfolio/wdp/4">
        <p>
          <span>Sketch Ideation</span>
          <br />
          The ideation phase produced a number of ways the concept could be executed. The top row and bottom right
          reflect a more open concept lower to the ground while the lower right sketch is more romantic and less
          feasible as a first iteration.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="WalkDenver Placemaking" />
        <img src={IMG2} alt="WalkDenver Placemaking" />
        <img src={IMG3} alt="WalkDenver Placemaking" />
        <img src={IMG4} alt="WalkDenver Placemaking" />
      </div>
    </Aux>
  );
};

export default WDP3;
