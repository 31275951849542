import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/potter_signature.png';
import styles from './HomePage.module.css';

const HomePage = () => (
  <div className={styles.Container}>
    <div className={styles.Content}>
      <img className={styles.Logo} src={Logo} alt="Cheyne Potter Signature" />
      <ul className={styles.Links}>
        <Link to="/about">About</Link>
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/contact">Contact</Link>
      </ul>
    </div>
  </div>
);

export default HomePage;
