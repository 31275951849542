import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/Cover.jpg';

const DCE7 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/6" next="/portfolio/dce/8">
        <p>
          <span>Large Freestanding Pylons</span>
          <br />
          These large signs are placed within the garden space and have information regarding the usage and spirit that
          gives the system life. The communal spirit of the crop exchange deserves a colorful, bright, approachable
          environment in which to thrive.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Denver Crop Exchange" className={rightStyle} />
    </Aux>
  );
};

export default DCE7;
