import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG from "../../../assets/images/twr/Directory.jpg";

import pstyles from "../PortfolioStyles.module.css";

const TWR6 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/5" next="/portfolio/twr/7">
        <p>
          <span>Freestanding Directory</span>
          <br />
          <br />
          This item is the 2nd point on the trail. Offering a finer view of the services offered inside the building but
          still sticking to major points of interest. This pylon was designed to be engaged by foot traffic and as such
          is close in height to a person.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR6;
