import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/IMG01.jpg';

const DCE2 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/" next="/portfolio/dce/3">
        <div>
          <p>
            <span>Problem</span>
            <br />
            Developing a brand identity that ties the work of a cross disciplinary team together. Creation of a system
            of usage for the users of the Community Produce Exchange. Understanding the most effective ways to
            communicate our community of Mariposa neighborhood utilizing environmental graphic design.
          </p>
          <p>
            <span>Solution</span>
            <br />
            First, developing an identity for the system to exist within. Second, creating wayfinding and placemaking
            environmental graphic design to communicate the use and spirit of the Community Produce Exchange system.
            Bridging the gap between the multiple disciplines and the community we serve.
          </p>
        </div>
      </LeftColumn>
      <img src={IMG} alt="Denver Crop Exchange" className={rightStyle} />
    </Aux>
  );
};

export default DCE2;
