import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/wdp/Cover.jpg';

const WDP1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn alt={true} title="WalkDenver Placemaking" prev="/portfolio/" next="/portfolio/wdp/2">
        <p>Experiential Graphic Design</p>
        <p>Placemaking</p>
        <p>Wayfinding</p>
      </LeftColumn>
      <img src={IMG} alt="WalkDenver Placemaking" className={rightStyle} />
    </Aux>
  );
};

export default WDP1;
