import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/bawm/Cover1.5x.jpg';

const BAWM6 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Black American West Museum" prev="/portfolio/bawm/5" next="/portfolio">
        <p>
          <span>Freestanding Name Sign</span>
          <br />
          The main element of this proposal is the large mural that would occupy the large unspiled canvas that is the
          wall of the building. Positioned on the end of a block this wall has a high level of exposure. I would work
          with local african-american artists in the final realization of this placemaking/ identity design.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Black American West Museum" className={rightStyle} />
    </Aux>
  );
};

export default BAWM6;
