import React from 'react';
import Arrows from '../Arrows/Arrows';
import Navbar from '../Navbar/Navbar';

import styles from './LeftColumn.module.css';

const LeftColumn = props => {
  let contentStyle = styles.Content;
  let arrow = null;

  if (props.alt === true) {
    contentStyle += ` ${styles.alt}`;
  }

  if (props.next === undefined && props.prev === undefined) {
    arrow = <Arrows hideLeft = {true} hideRight = {true} />
  } else if (props.next === undefined) {
    arrow = <Arrows hideRight = {true} prev={props.prev} />
  } else if (props.prev === undefined) {
    arrow = <Arrows hideLeft = {true} next={props.next} />
  } else {
    arrow = <Arrows prev={props.prev} next={props.next} />
  }

  return (
    <div className={contentStyle}>
      <Navbar />
      <div className={styles.Text}>
        <h2>{props.title}</h2>
        {props.children}
      </div>
      {arrow}
    </div>
  );
};

export default LeftColumn;
