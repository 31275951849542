import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/thesis/pillars.jpg';
import IMG2 from '../../../assets/images/thesis/pillars_birdseye.jpg';
import IMG3 from '../../../assets/images/thesis/barriers.jpg';
import IMG4 from '../../../assets/images/thesis/barriers_birdseye.jpg';

const Thesis3 = () => {
  const rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Existing in Blackness" prev="/portfolio/thesis/2" next="/portfolio/thesis/4">
        <p>
          <span>Initial Form Sketches</span>
          <br />
          The summer leading up to the course was spent in early discovery phase. At this time, my form was triadic with
          3 pillars around a central sphere. Light was included as a reference to privledge based on who has and doesn’t
          have access. <br />
          <br />
          Further refinement lead to the development of planar barriers which also blocked a light source from reaching
          an internal figure.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Existing in Blackness" />
        <img src={IMG2} alt="Existing in Blackness" />
        <img src={IMG3} alt="Existing in Blackness" />
        <img src={IMG4} alt="Existing in Blackness" />
      </div>
    </Aux>
  );
};

export default Thesis3;
