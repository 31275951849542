import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/hb/Wall.jpg';

const HB1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Happy Brandz Identity Design" prev="/portfolio/" next="/portfolio/hb/2">
        <p>Identity Design</p>
        <p>Branding</p>
        <p>Art Direction</p>
      </LeftColumn>
      <img src={IMG} alt="Happy Brandz" className={rightStyle} />
    </Aux>
  );
};

export default HB1;
