import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/wdp/Vistas1.png';
import IMG2 from '../../../assets/images/wdp/Vistas2.png';

const WDP3 = () => {
  let rightStyle = pstyles.TwoRows + ` ${pstyles.Contain}`;
  
  return (
    <Aux>
      <LeftColumn title="WalkDenver Placemaking" prev="/portfolio/wdp/3" next="/portfolio/">
        <p>
          <span>Vector Realization</span>
          <br />
          My final concept was a number of small parklettes decorated in relation to their placement in the
          neighborhood. This specific example would be placed along the northern boundary of Montbello in a green belt
          along 56th Street. This would allow any who climb to the top a beautiful view of Rocky Mountain Arsenal
          National Wildlife Refuge. <br />
          <br /> The bottom diagram shows the interior angles feature the stairs and the bench interior. Inside the nook
          is a typographic pattern as an element in the identity.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="WalkDenver Placemaking" />
        <img src={IMG2} alt="WalkDenver Placemaking" />
      </div>
    </Aux>
  );
};

export default WDP3;
