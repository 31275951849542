import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/Small.jpg';

const CC10 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/9" next="/portfolio">
        <p>
          <span>Small Freestanding Sign</span>
          <br />
          The smallest items within the system offer a solution to placement along the often narrow paths of the now
          state park. The small size lends itself to the information housed on these which would be supplemental
          information giving more context for the park in person.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC10;
