import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/Catastrophe.jpg';

const CC2 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/" next="/portfolio/cc/3">
        <div>
          <p>
            <span>Problem</span>
            <br />
            Design an environmental graphic design system to educate visitors to the state park aabout the histroy of
            the park and surrounding area. Also create a celebration and narrative of the people who rushed to save the
            lives of those in the way of the flood.
          </p>
        </div>
        <div>
          <p>
            <span>Solution</span>
            <br />
            This Project educates visitors to the current state park
            about this even and the power of communication. Creation of an interpretive and interactive design system
            that educates and entertains.
          </p>
        </div>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC2;
