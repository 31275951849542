import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/dce/Sketch1.jpg';
import IMG2 from '../../../assets/images/dce/Sketch2.jpg';
import IMG3 from '../../../assets/images/dce/Sketch3.jpg';
import IMG4 from '../../../assets/images/dce/Sketch4.jpg';

const DCE4 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/3" next="/portfolio/dce/5">
        <p>
          <span>Ideation Sketches</span>
          <br />
          Independently, each member of the team produced a concept for the communication and overall look for the
          system. These are a number of my sketches giving an idea of how these items might exist in space around the
          centralized box.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Denver Crop Exchange" />
        <img src={IMG2} alt="Denver Crop Exchange" />
        <img src={IMG3} alt="Denver Crop Exchange" />
        <img src={IMG4} alt="Denver Crop Exchange" />
      </div>
    </Aux>
  );
};

export default DCE4;
