import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

//Pages
import AboutPage from './containers/AboutPage/AboutPage';
import ContactPage from './containers/ContactPage/ContactPage';
import HomePage from './containers/HomePage/HomePage';
import PortfolioHome from './containers/Portfolio/PortfolioHome/PortfolioHome';
import BAWM1 from './containers/Portfolio/BAWM/BAWM1';
import BAWM2 from './containers/Portfolio/BAWM/BAWM2';
import BAWM3 from './containers/Portfolio/BAWM/BAWM3';
import BAWM4 from './containers/Portfolio/BAWM/BAWM4';
import BAWM5 from './containers/Portfolio/BAWM/BAWM5';
import BAWM6 from './containers/Portfolio/BAWM/BAWM6';
import CC1 from './containers/Portfolio/CC/CC1';
import CC2 from './containers/Portfolio/CC/CC2';
import CC3 from './containers/Portfolio/CC/CC3';
import CC4 from './containers/Portfolio/CC/CC4';
import CC5 from './containers/Portfolio/CC/CC5';
import CC6 from './containers/Portfolio/CC/CC6';
import CC7 from './containers/Portfolio/CC/CC7';
import CC8 from './containers/Portfolio/CC/CC8';
import CC9 from './containers/Portfolio/CC/CC9';
import CC10 from './containers/Portfolio/CC/CC10';
import DCE1 from './containers/Portfolio/DCE/DCE1';
import DCE2 from './containers/Portfolio/DCE/DCE2';
import DCE3 from './containers/Portfolio/DCE/DCE3';
import DCE4 from './containers/Portfolio/DCE/DCE4';
import DCE5 from './containers/Portfolio/DCE/DCE5';
import DCE6 from './containers/Portfolio/DCE/DCE6';
import DCE7 from './containers/Portfolio/DCE/DCE7';
import DCE8 from './containers/Portfolio/DCE/DCE8';
import DCE9 from './containers/Portfolio/DCE/DCE9';
import Exhib1 from './containers/Portfolio/Exhib/Exhib1';
import Exhib2 from './containers/Portfolio/Exhib/Exhib2';
import Exhib3 from './containers/Portfolio/Exhib/Exhib3';
import Exhib4 from './containers/Portfolio/Exhib/Exhib4';
import HB1 from './containers/Portfolio/HB/HB1';
import HB2 from './containers/Portfolio/HB/HB2';
import HB3 from './containers/Portfolio/HB/HB3';
import HB4 from './containers/Portfolio/HB/HB4';
import Thesis1 from './containers/Portfolio/Thesis/Thesis1';
import Thesis2 from './containers/Portfolio/Thesis/Thesis2';
import Thesis3 from './containers/Portfolio/Thesis/Thesis3';
import Thesis4 from './containers/Portfolio/Thesis/Thesis4';
import Thesis5 from './containers/Portfolio/Thesis/Thesis5';
import Thesis6 from './containers/Portfolio/Thesis/Thesis6';
import TWR1 from './containers/Portfolio/TWR/TWR1';
import TWR2 from './containers/Portfolio/TWR/TWR2';
import TWR3 from './containers/Portfolio/TWR/TWR3';
import TWR4 from './containers/Portfolio/TWR/TWR4';
import TWR5 from './containers/Portfolio/TWR/TWR5';
import TWR6 from './containers/Portfolio/TWR/TWR6';
import TWR7 from './containers/Portfolio/TWR/TWR7';
import TWR8 from './containers/Portfolio/TWR/TWR8';
import WDP1 from './containers/Portfolio/WDP/WDP1';
import WDP2 from './containers/Portfolio/WDP/WDP2';
import WDP3 from './containers/Portfolio/WDP/WDP3';
import WDP4 from './containers/Portfolio/WDP/WDP4';


//Styles
import './App.css';

function App() {
  library.add(fab);

  return (
    <div className="App">
      <Router>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/contact" exact component={ContactPage} />
        <Route path="/portfolio" exact component={PortfolioHome} />
        <Route path="/portfolio/bawm" exact component={BAWM1} />
        <Route path="/portfolio/bawm/2" exact component={BAWM2} />
        <Route path="/portfolio/bawm/3" exact component={BAWM3} />
        <Route path="/portfolio/bawm/4" exact component={BAWM4} />
        <Route path="/portfolio/bawm/5" exact component={BAWM5} />
        <Route path="/portfolio/bawm/6" exact component={BAWM6} />
        <Route path="/portfolio/cc" exact component={CC1} />
        <Route path="/portfolio/cc/2" exact component={CC2} />
        <Route path="/portfolio/cc/3" exact component={CC3} />
        <Route path="/portfolio/cc/4" exact component={CC4} />
        <Route path="/portfolio/cc/5" exact component={CC5} />
        <Route path="/portfolio/cc/6" exact component={CC6} />
        <Route path="/portfolio/cc/7" exact component={CC7} />
        <Route path="/portfolio/cc/8" exact component={CC8} />
        <Route path="/portfolio/cc/9" exact component={CC9} />
        <Route path="/portfolio/cc/10" exact component={CC10} />
        <Route path="/portfolio/dce" exact component={DCE1} />
        <Route path="/portfolio/dce/2" exact component={DCE2} />
        <Route path="/portfolio/dce/3" exact component={DCE3} />
        <Route path="/portfolio/dce/4" exact component={DCE4} />
        <Route path="/portfolio/dce/5" exact component={DCE5} />
        <Route path="/portfolio/dce/6" exact component={DCE6} />
        <Route path="/portfolio/dce/7" exact component={DCE7} />
        <Route path="/portfolio/dce/8" exact component={DCE8} />
        <Route path="/portfolio/dce/9" exact component={DCE9} />
        <Route path="/portfolio/exhib/" exact component={Exhib1} />
        <Route path="/portfolio/exhib/2" exact component={Exhib2} />
        <Route path="/portfolio/exhib/3" exact component={Exhib3} />
        <Route path="/portfolio/exhib/4" exact component={Exhib4} />
        <Route path="/portfolio/hb" exact component={HB1} />
        <Route path="/portfolio/hb/2" exact component={HB2} />
        <Route path="/portfolio/hb/3" exact component={HB3} />
        <Route path="/portfolio/hb/4" exact component={HB4} />
        <Route path="/portfolio/thesis" exact component={Thesis1} />
        <Route path="/portfolio/thesis/2" exact component={Thesis2} />
        <Route path="/portfolio/thesis/3" exact component={Thesis3} />
        <Route path="/portfolio/thesis/4" exact component={Thesis4} />
        <Route path="/portfolio/thesis/5" exact component={Thesis5} />
        <Route path="/portfolio/thesis/6" exact component={Thesis6} />
        <Route path="/portfolio/twr" exact component={TWR1} />
        <Route path="/portfolio/twr/2" exact component={TWR2} />
        <Route path="/portfolio/twr/3" exact component={TWR3} />
        <Route path="/portfolio/twr/4" exact component={TWR4} />
        <Route path="/portfolio/twr/5" exact component={TWR5} />
        <Route path="/portfolio/twr/6" exact component={TWR6} />
        <Route path="/portfolio/twr/7" exact component={TWR7} />
        <Route path="/portfolio/twr/8" exact component={TWR8} />
        <Route path="/portfolio/wdp" exact component={WDP1} />
        <Route path="/portfolio/wdp/2" exact component={WDP2} />
        <Route path="/portfolio/wdp/3" exact component={WDP3} />
        <Route path="/portfolio/wdp/4" exact component={WDP4} />
      </Router>
    </div>
  );
}

export default App;
