import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/thesis/Cover.jpg';

const Thesis1 = () => {
  const rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Existing in Blackness" prev="/portfolio/" next="/portfolio/thesis/2">
        <p>Thesis Exhibition</p>
        <p>Environmental Graphic Design</p>
        <p>Placemaking</p>
        <p>Identity Design</p>
        <p>Sculpture</p>
      </LeftColumn>
      <img src={IMG} alt="Existing in Blackness" className={rightStyle} />
    </Aux>
  );
};

export default Thesis1;
