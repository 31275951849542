import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/All.png';

const CC1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Castlewood Catastrophe" prev="/portfolio/" next="/portfolio/cc/2">
        <p>Environmental Graphic Design</p>
        <p>Branding</p>
        <p>Narrative Design</p>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC1;
