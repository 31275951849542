import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/Cover.jpg';

const DCE1 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn alt={true} title="Denver Crop Exchange" prev="/portfolio/" next="/portfolio/dce/2">
        <p>Environmental Graphic Design</p>
        <p>Branding</p>
        <p>Narrative Design</p>
      </LeftColumn>
      <img src={IMG} alt="Denver Crop Exchange" className={rightStyle} />
    </Aux>
  );
};

export default DCE1;
