import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/Rules.jpg';
import IMG2 from '../../../assets/images/dce/Rules2.png';

const DCE8 = () => {
  let rightStyle = pstyles.TwoByOne + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/7" next="/portfolio/dce/9">
        <p>
          <span>Garden Rules</span>
          <br />
          As part of the site analysis, I noted a number of elements which existed as part of the system but were
          scattered and disparate with no clear identity binding them together. To rectify this, I redesigned the rules
          signage complete with their copy laid out in a more clear manner.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Denver Crop Exchange" />
        <img src={IMG2} alt="Denver Crop Exchange" />
      </div>
    </Aux>
  );
};

export default DCE8;
