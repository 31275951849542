import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG1 from "../../../assets/images/twr/FlushMap.jpg";

import pstyles from "../PortfolioStyles.module.css";

const TWR7 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/6" next="/portfolio/twr/8">
        <p>
          <span>Flush Wall Directory</span>
          <br />
          <br />
          If a user cannot find their desired destination, they are able to refer to a flush wall directory and map
          which gives a detailed list of every room within the Tivoli building. This comprehensive item is placed in
          major intersections within the building increasing likelihood of use.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR7;
