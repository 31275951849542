import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG1 from "../../../assets/images/twr/6.jpg";
import IMG2 from "../../../assets/images/twr/13.jpg";
import IMG3 from "../../../assets/images/twr/19.jpg";
import IMG4 from "../../../assets/images/twr/22.jpg";

import pstyles from "../PortfolioStyles.module.css";

const TWR4 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/3" next="/portfolio/twr/5">
        <p>
          <span>Brandmark</span>
          <br />
          <br />A collection of sketches which highlight how the visual identity of the system was considered. Top Left
          would be come the freestanding directory. Top right would inform the wall hanging room IDs. Bottom left is a
          concept for the monolithic pylon. While the bottom right sketch will become the flush wall map.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Tivoli Wayfinding Redesign" />
        <img src={IMG2} alt="Tivoli Wayfinding Redesign" />
        <img src={IMG3} alt="Tivoli Wayfinding Redesign" />
        <img src={IMG4} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR4;
