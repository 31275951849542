import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG1 from "../../../assets/images/thesis/catalog_spread.jpg";

const Exhib4 = () => {
  const rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn
        title="Existing in Blackness"
        prev="/portfolio/exhib/3"
        next="/portfolio"
      >
        <p>
          <span>Responsibilities</span>
          <br />
          I managed deadlines and communicated closely between two
          departments to ensure everyone had the best representation possible
          for their work. The position required that deadlines be met as they
          were narrow with a number of items that needed to be sent to our
          printer.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Existing in Blackness" />
      </div>
    </Aux>
  );
};

export default Exhib4;
