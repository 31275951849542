import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG1 from "../../../assets/images/thesis/catalog_front.jpg";

const Exhib2 = () => {
  const rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn
        title="Exhibition Catalog"
        prev="/portfolio/exhib"
        next="/portfolio/exhib/3"
      >
        <p>
          <span>Cover Design</span>
          <br />
          Every semester the Metropolitan University of Denver holds a student
          show which highlights the Bachelor of Fine Art in Communication Design
          and Studio Art’s thesis work. This cap stone culminates in a gallery
          opening during which the public is invited to see our work and ask
          questions first hand.
        </p>
      </LeftColumn>

      <div className={rightStyle}>
        <img src={IMG1} alt="Catalog Front" />
      </div>
    </Aux>
  );
};

export default Exhib2;
