import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/cc/EGDV1.jpg';
import IMG2 from '../../../assets/images/cc/EGDV2.jpg';
import IMG3 from '../../../assets/images/cc/EGDV3.jpg';
import IMG4 from '../../../assets/images/cc/EGDV4.jpg';

const CC6 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/5" next="/portfolio/cc/7">
        <div>
          <p>
            <span>EGD Vectors</span>
            <br />
            Refinements to the sketches are the next step in the process. At this stage the imagey and narrative copy
            could be added. The curves cut into the blue forms reference water and the now empty resevoir.
          </p>
          <p>
            Some items in the system included proposed interactive elements such as records of transcripts or rushing
            water sound effects. These help put the audience into that place nearly a hundred years ago.
          </p>
        </div>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Castlewood Catastrophe" />
        <img src={IMG2} alt="Castlewood Catastrophe" />
        <img src={IMG3} alt="Castlewood Catastrophe" />
        <img src={IMG4} alt="Castlewood Catastrophe" />
      </div>
    </Aux>
  );
};

export default CC6;
