import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/dce/NamePlate.jpg';

const DCE6 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/5" next="/portfolio/dce/7">
        <p>
          <span>Name Plate</span>
          <br />
          The industrial design team included in their design an extended amount of post which could be used to attach
          signage. I took advantage of this opportunity and made it the location of the identity signage.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Denver Crop Exchange" className={rightStyle} />
    </Aux>
  );
};

export default DCE6;
