import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG from "../../../assets/images/twr/Building.png";

import pstyles from "../PortfolioStyles.module.css";

const TWR2 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/" next="/portfolio/twr/3">
        <p>
          <span>Problem</span>
          <br /> Currently the visual makeup of the Tivoli’s signage is a hodgepodge of quick solutions. Institutions
          are regularly called out via vinyl stickers applied to signs as an afterthought. How can the team unify the
          identity of the space?
          <span>Solution</span>
          <br />
          Creation of a new identity and wayfinding system which will unify the site better than the current situation.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR2;
