import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG1 from "../../../assets/images/thesis/Gloom.jpg";
import IMG2 from "../../../assets/images/thesis/Vision.jpg";
import IMG3 from "../../../assets/images/thesis/Complicity.jpg";

const Thesis6 = () => {
  const rightStyle = pstyles.ThreeCols + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn
        title="Existing in Blackness"
        prev="/portfolio/thesis/5"
        next="/portfolio/"
      >
        <p>
          <span>Color</span>
          <br />
          My piece’s use of color reflects the mixing of identities into a new
          whole. Red represents the anger and passion of the blackness. The blue
          is subdued which lends itself to the malaise of whiteness. These meet
          in the middle to created purple; a rich royal color that highlights
          the hierarchy present in the triumvirate.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Gloom" />
        <img src={IMG2} alt="Vision" />
        <img src={IMG3} alt="Complicity" />
      </div>
    </Aux>
  );
};

export default Thesis6;
