import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import IMG from '../../../assets/images/twr/Redux.png';

import pstyles from '../PortfolioStyles.module.css';

const TWR3 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/2" next="/portfolio/twr/4">
        <p>
          <span>Brand Mark</span>
          <br /> A cohesive system starts with the identity. Figuring out this important elelment during the early
          stages of a project helps to guide the rest. A number of concepts and thumbnails were produced after gathering
          research about the site and its history.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR3;
