import React from 'react';
import Aux from '../../hoc/Auxiliary';
import LeftColumn from '../../components/LeftColumn/LeftColumn';

import Portrait from '../../assets/images/portrait2.jpg';

import styles from './AboutPage.module.css';

const AboutPage = () => (
  <Aux>
    <LeftColumn title="About Cheyne">
      <p>
        Cheyne Potter is a designer born and raised in Denver, Colorado. He is an avid student of design, earning his
        Associate of Applied Science in Multi Media Graphic Design at the Community College of Aurora. Currently, Cheyne
        is a Senior enrolled at Metropolitan State University of Denver where he is pursuing a Bachelor of Fine Arts in
        Communication Design in their NASAD accredited department of art. During this time, Cheyne Potter worked in a
        freelance capacity, working with clients to shape their vision. Be it brand identity or T-shirt design, everyone
        has something they need to say, he helps facilitate that process.
      </p>
    </LeftColumn>
    <div className={styles.RightColumn}>
      <img className={styles.Portrait} src={Portrait} alt="Cheyne Potter"></img>
    </div>
  </Aux>
);

export default AboutPage;
