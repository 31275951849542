import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/hb/Signatures.png';

const HB4 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Happy Brandz Identity Design" prev="/portfolio/hb/3" next="/portfolio/">
        <p>
          <span>Brand Standards Guide</span>
          <br />
          These are some of the early considerations for the brand mark in its multiple lockups. Single color and full
          color, sizes and clear space, and a simle yet sweet tagline. All vital elements for the overall strength of
          the brand.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Happy Brandz" className={rightStyle} />
    </Aux>
  );
};

export default HB4;
