import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import VID from '../../../assets/images/bawm/bawm.mp4';

const BAWM5 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Black American West Museum" prev="/portfolio/bawm/4" next="/portfolio/bawm/6">
        <p>
          <span>Freestanding Name Sign Animation</span>
          <br />
          As a test of technical skills I created a 3D CAD render and a short video to showcase the element in the
          round.
        </p>
      </LeftColumn>
      <video loop autoPlay controls className={rightStyle}>
        <source src={VID} type="video/mp4" />
      </video>
    </Aux>
  );
};

export default BAWM5;
