import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';
import styles from './DCE.module.css';

//Images
import IMG1 from '../../../assets/images/dce/Vector1.jpg';
import IMG2 from '../../../assets/images/dce/Vector2.jpg';
import IMG3 from '../../../assets/images/dce/Vector3.jpg';
import IMG4 from '../../../assets/images/dce/Vector4.jpg';
import IMG5 from '../../../assets/images/dce/Vector5.jpg';
import IMG6 from '../../../assets/images/dce/Vector6.jpg';

const DCE5 = () => {
  return (
    <Aux>
      <LeftColumn title="Denver Crop Exchange" prev="/portfolio/dce/4" next="/portfolio/dce/6">
        <p>
          <span>EGD System Vectors</span>
          <br />
          The system in its entirety. Clock wise starting top left there is the main signage, the garden rules sign.
          garden owner/score card sign, and the three primary communication elements carrot, corn, and pea respectively.
        </p>
      </LeftColumn>
      <div className={pstyles.TwoRows}>
        <div className={styles.DCE5Top + ` ${pstyles.Contain}`}>
          <img src={IMG1} alt="Denver Crop Exchange" className={styles.DCE5_1} />
          <img src={IMG2} alt="Denver Crop Exchange" className={styles.DCE5_2} />
          <img src={IMG3} alt="Denver Crop Exchange" className={styles.DCE5_3} />
        </div>
        <div className={styles.DCE5Bottom + ` ${pstyles.Contain}`}>
          <img src={IMG4} alt="Denver Crop Exchange" className={styles.DCE5_4} />
          <img src={IMG5} alt="Denver Crop Exchange" className={styles.DCE5_5} />
          <img src={IMG6} alt="Denver Crop Exchange" className={styles.DCE5_6} />
        </div>
      </div>
    </Aux>
  );
};

export default DCE5;
