import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/Wide.jpg';

const CC8 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/7" next="/portfolio/cc/9">
        <p>
          <span>Wide Freestanding Sign</span>
          <br />
          Major narrative point of the system talks about the “Race to Save Lives” in which the dam watchman and the
          Franktown phone operator worked to spread the word of danger
        </p>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC8;
