import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/potter_signature.png';
import styles from './Navbar.module.css';

const Navbar = () => (
  <div className={styles.Navbar}>
    <Link to="/">
      <img className={styles.Logo} src={Logo} alt="Cheyne Potter Signature" />
    </Link>
    <div className={styles.MenuButton}>{/*TODO: Side Drawer*/}</div>
  </div>
);

export default Navbar;
