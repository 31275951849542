import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/hb/2_1.jpg';
import IMG2 from '../../../assets/images/hb/2_2.jpg';
import IMG3 from '../../../assets/images/hb/2_3.jpg';
import IMG4 from '../../../assets/images/hb/2_4.jpg';

const HB2 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Happy Brandz Identity Design" prev="/portfolio/hb" next="/portfolio/hb/3">
        <p>
          <span>Ideation</span>
          <br />I produced a number of concepts based on research given to me by the internal team and by my own
          research during the ideation phase. This lead to a number of sketches as you can see to the right. These were
          shown to the executive team and adjusted according to feedback. In turn these were vectorized and the process
          of creating a system of brand standards began in earnest.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Happy Brandz" />
        <img src={IMG2} alt="Happy Brandz" />
        <img src={IMG3} alt="Happy Brandz" />
        <img src={IMG4} alt="Happy Brandz" />
      </div>
    </Aux>
  );
};

export default HB2;
