import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/cc/EGD1.jpg';
import IMG2 from '../../../assets/images/cc/EGD2.jpg';
import IMG3 from '../../../assets/images/cc/EGD3.jpg';
import IMG4 from '../../../assets/images/cc/EGD4.jpg';

const CC5 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/4" next="/portfolio/cc/6">
        <p>
          <span>EGD Sketches</span>
          <br />A number of concepts were sketched out for what the system would look like. To tie the concept to the
          design the idea of multiple planes which evoke a breaking dam and the water which now threatens to sweep out
          from it.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Castlewood Catastrophe" />
        <img src={IMG2} alt="Castlewood Catastrophe" />
        <img src={IMG3} alt="Castlewood Catastrophe" />
        <img src={IMG4} alt="Castlewood Catastrophe" />
      </div>
    </Aux>
  );
};

export default CC5;
