import React, { Component } from 'react';
import Axios from 'axios';
import LeftColumn from '../../components/LeftColumn/LeftColumn';

import styles from './ContactPage.module.css';

export class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      disabled: false,
      emailSent: null,
    };
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({
      disabled: true,
    });

    Axios.post('http://localhost:3030/api/email', this.state)
      .then(res => {
        if (res.data.success) {
          this.setState({
            disabled: false,
            emailSent: true,
          });
        } else {
          this.setState({
            disabled: false,
            emailSent: false,
          });
        }
      })
      .catch(err => {
        console.log(err);

        this.setState({
          disabled: false,
          emailSent: false,
        });
      });
  };

  render() {
    return (
      <LeftColumn title="Contact Me">
        <form className={styles.ContactForm} onSubmit={this.handleSubmit}>
          <fieldset>
            <label htmlFor="full-name">Full Name</label>
            <input
              type="text"
              name="name"
              id="full-name"
              align="right"
              value={this.state.name}
              onChange={this.handleChange}
            />
          </fieldset>
          <fieldset>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" value={this.state.email} onChange={this.handleChange} />
          </fieldset>
          <fieldset>
            <label htmlFor="message">Message</label>
            <textarea
              cols="5"
              name="message"
              id="message"
              value={this.state.message}
              onChange={this.handleChange}
            />
          </fieldset>
          <input type="submit" disabled={this.state.disabled} value="Submit" />

          {this.state.emailSent === true && <p className={styles.SuccessMessage}>Email Sent</p>}
          {this.state.emailSent === false && <p className={styles.FailMessage}>Email Not Sent</p>}
        </form>
      </LeftColumn>
    );
  }
}

export default ContactPage;
