import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import IMG1 from "../../../assets/images/twr/flag_mounted_2.png";
import IMG2 from "../../../assets/images/twr/RoomID.jpg";

import pstyles from "../PortfolioStyles.module.css";

const TWR8 = () => {
  let rightStyle = pstyles.TwoRows + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn title="Tivoli Wayfinding Redesign" prev="/portfolio/twr/7" next="/portfolio">
        <p>
          <span>Flag Mounted + Flush Room IDs</span>
          <br />
          <br />
          The final items in the system are the destination markers. These signify to the user that they are where they
          need to be, providing the room number, the respective institution, and the service that room provides. This
          will be a vital piece in unifying the visual identity and wayfinding of the space.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Tivoli Wayfinding Redesign" />
        <img src={IMG2} alt="Tivoli Wayfinding Redesign" />
      </div>
    </Aux>
  );
};

export default TWR8;
