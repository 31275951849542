import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG from '../../../assets/images/cc/Tall.jpg';

const CC9 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;
  
  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/8" next="/portfolio/cc/10">
        <p>
          <span>Tall Freestanding Sign</span>
          <br />
          The tall freestanding pieces highlight the aftermath of the dam flooding. The effects were felt along the Cherrycreek River all the way into the the confluence with the South Platte in downtown Denver.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Castlewood Catastrophe" className={rightStyle} />
    </Aux>
  );
};

export default CC9;
