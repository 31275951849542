import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/bawm/Front.jpg';
import IMG2 from '../../../assets/images/bawm/Side.jpg';

const BAWM4 = () => {
  let rightStyle = pstyles.TwoCols + ` ${pstyles.Contain}`;
  
  return (
    <Aux>
      <LeftColumn title="Black American West Museum" prev="/portfolio/bawm/3" next="/portfolio/bawm/5">
        <p>
          <span>Freestanding Name Sign</span>
          <br />
          In my site analysis I noted that the museum was using a vinyl banner as their primary signage element. This
          did the space a diservice and would be replaced with a permenant freestanding sign that references the
          building in materials and design. This would help draw attention to the site as a public learning space.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Black American West Museum" />
        <img src={IMG2} alt="Black American West Museum" />
      </div>
    </Aux>
  );
};

export default BAWM4;
