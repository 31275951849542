import React from 'react';
import Aux from '../../../hoc/Auxiliary';
import LeftColumn from '../../../components/LeftColumn/LeftColumn';

import pstyles from '../PortfolioStyles.module.css';

//Images
import IMG1 from '../../../assets/images/cc/Mark1.jpg';
import IMG2 from '../../../assets/images/cc/Mark2.jpg';
import IMG3 from '../../../assets/images/cc/Mark3.jpg';
import IMG4 from '../../../assets/images/cc/Mark4.jpg';

const CC3 = () => {
  let rightStyle = pstyles.TwoByTwo + ` ${pstyles.Contain}`;

  return (
    <Aux>
      <LeftColumn title="Castlewood Catastrophe" prev="/portfolio/cc/2" next="/portfolio/cc/4">
        <p>
          <span>Brand Mark</span>
          <br />A cohesive system starts with the identity. Figuring out this important elelment during the early stages
          of a project helps to guide the rest. A number of concepts and thumbnails were produced after gathering
          research about the site and its history.
        </p>
      </LeftColumn>
      <div className={rightStyle}>
        <img src={IMG1} alt="Castlewood Catastrophe" />
        <img src={IMG2} alt="Castlewood Catastrophe" />
        <img src={IMG3} alt="Castlewood Catastrophe" />
        <img src={IMG4} alt="Castlewood Catastrophe" />
      </div>
    </Aux>
  );
};

export default CC3;
