import React from "react";
import Aux from "../../../hoc/Auxiliary";
import LeftColumn from "../../../components/LeftColumn/LeftColumn";

import pstyles from "../PortfolioStyles.module.css";

//Images
import IMG from "../../../assets/images/thesis/Color.jpg";

const Thesis5 = () => {
  let rightStyle = pstyles.RightIMG + ` ${pstyles.Cover}`;

  return (
    <Aux>
      <LeftColumn
        title="Existing in Blackness"
        prev="/portfolio/thesis/4"
        next="/portfolio/thesis/6"
      >
        <p>
          <span>Hybridity</span>
          <br />I synthesized concepts, beliefs, materials, and tools separated
          by hundreds of years yet still tied to us today. I offer a series of
          forms for those experiencing the injustices of a society informed by
          white suprematism. A beacon that says you are not alone in the
          experience of a mixed race identity.
        </p>
      </LeftColumn>
      <img src={IMG} alt="Existing in Blackness" className={rightStyle} />
    </Aux>
  );
};

export default Thesis5;
